
































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IUserProfile } from '@/interfaces';
import { readAdminUsers } from '@/store/admin/getters';
import { dispatchGetUsers, dispatchDeleteUserById } from '@/store/admin/actions';
import { parseDate, USER_ROLES } from '@/utils';

@Component
export default class AdminUsers extends Vue {
  public roles = USER_ROLES;
  public parseDate = parseDate;
  public pagination = {
    ascending: true,
    sortBy: 'username',
    rowsPerPage: 25,
    page: 1,
  };
  public search = "";
  public filterUsers = [] as any;
  public dialog = false;
  public id = '';
  public username = '';

  @Watch("search")
  public searchName(searchValue) {
    if (searchValue){
      this.filterUsers = this.filterUsers.filter((e) => {
        if (e.username){
          return e.username.toLowerCase().includes(searchValue.toLowerCase());
        }
      });
    } else {
      this.filterUsers = [...readAdminUsers(this.$store)];
    }
  }

  public headers = [
    {
      text: 'Username',
      sortable: true,
      value: 'username',
      align: 'left',
    },
    {
      text: 'Email',
      sortable: true,
      value: 'email',
      align: 'left',
    },
    {
      text: 'Full Name',
      sortable: true,
      value: 'full_name',
      align: 'left',
    },
    {
      text: 'Is Active',
      sortable: true,
      value: 'is_active',
      align: 'left',
    },
    // {
    //   text: 'Is Admin',
    //   sortable: true,
    //   value: 'isSuperuser',
    //   align: 'left',
    // },
    {
      text: 'Role',
      sortable: true,
      value: 'role',
      align: 'left',
    },
    {
      text: 'Expired Date',
      sortable: true,
      value: 'expired_date',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
      align: "center",
    },
  ];
  // get users() {
  //   return readAdminUsers(this.$store);
  // }

  public async mounted() {
    await dispatchGetUsers(this.$store);
    this.filterUsers = [...readAdminUsers(this.$store)];
  }

  public async confirm() {
    this.dialog = false;
    await dispatchDeleteUserById(this.$store, Number(this.id));
    await dispatchGetUsers(this.$store);
    this.filterUsers = [...readAdminUsers(this.$store)];
  }

}
